/* global google */
import { useRef, useCallback, useMemo, useState, useEffect, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import GoogleMap from 'google-maps-react-markers';
import Supercluster from 'supercluster';
import {
  Flex,
  RaiteTab,
  MapCard,
  useClickOutside,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import {
  FilterType,
  getSelectedLanguage,
  telAvivBounds,
  telAvivGeolocation,
  Y_OFFSET_FOR_DOWN_LAYOUT,
} from '../../../../constants';
import { getTranslation, openLink } from '../../../../helpers/utils';
import { useTheme } from '../../../../hooks';
import { useGeolocation } from '../../../../hooks/useGeolocation';
import { useGetOrganisations } from '../../../../hooks/useGetOrganisations';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateShowMenu } from '../../../../store/redux-slices/headerSlice';
import { selectHistory } from '../../../../store/redux-slices/historySlice';
import { selectAppliedFiltersCount } from '../../../../store/redux-slices/offersFilterSlice';
import { selectOrganisation } from '../../../../store/redux-slices/organisationSlice';
import { searchState } from '../../../../store/redux-slices/searchSlice';
import {
  LatLngLiteral,
  MapOptions,
  GoogleMapType,
  MarkerType,
  MapPropsType,
  GoogleGeometryType,
} from '../../../../types';
import { GeolocationType } from '../../../../types/general';
import ClusterMarker from './ClusterMarker';
import FilterResultsSidebar from './FilterResultsSidebar';
import {
  getRightBottomButtons,
  getRightTopMapButtons,
  getLeftTopMapButtons,
  xLng,
  yLat,
  calculateDistance,
  getMarkerLatLngLiteral,
  clearMapButtons,
} from './helpers';
import MapPointLocation from './MapPointLocation';
import { MarkerWrapper, PointWrapper, CardWrapper, GoogleMapWrapper } from './style';

type CustomMapProps = {
  onChangeView: () => void;
  onFilterOpen?: () => void;
  mapZoom?: number;
  centerOfMap?: GeolocationType;
  setShareModalOpen?: () => void;
  setFavouriteModalOpen?: () => void;
  setShareOrganisationId?: (orgId: string) => void;
};

const options: MapOptions = {
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  mapId: '4a89a47c8aa391b1', // see https://console.cloud.google.com/google/maps-apis/studio/maps?project=thermal-origin-385108&supportedpurview=project
  clickableIcons: false,
  gestureHandling: 'greedy',
};

const clusterOptions = {
  maxZoom: 22,
  radius: 72,
};

const CustomMap = ({
  onFilterOpen,
  onChangeView,
  mapZoom,
  centerOfMap,
  setShareModalOpen,
  setFavouriteModalOpen,
  setShareOrganisationId,
}: CustomMapProps) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const { topCategory } = useAppSelector(selectHistory).history;
  const { isLoading, organisations: categoryOrganisations } = useGetOrganisations(topCategory);
  const { organisations: searchOrganisations, filterType } = useAppSelector(searchState);
  const location = useLocation();

  const organisations = filterType === FilterType.SEARCH ? searchOrganisations : categoryOrganisations;
  const { orgId } = useParams();

  const organisationsToMap = location.pathname.includes('organisation')
    ? organisations.filter(org => org.id === orgId)
    : organisations;
  const mappedOrgs = organisationsToMap.map(org => ({
    ...org,
    title: org.orgName ? getTranslation(org.orgName, language) : org.name,
    image: org.mainPhoto,
    headline: 'remove after...',
    geometry: { coordinates: [org.coordinates.lng || 31, org.coordinates.lat || 31] },
  }));

  const { askedLocation } = useGeolocation();
  const { geolocation, bounds: area } = useAppSelector(searchState);
  const { isShowSearch } = useAppSelector(selectOrganisation);
  const userLocation: LatLngLiteral =
    askedLocation && geolocation ? { lat: Number(geolocation.lat), lng: Number(geolocation.lng) } : telAvivGeolocation;
  // TODO Needs to be reviewed: mapCenter could be userLocation if we need to center map on user position on initial load
  const mapCenter: LatLngLiteral = centerOfMap || geolocation || telAvivGeolocation;

  const mapRef = useRef<GoogleMapType | null>(null);
  const mapsControlsRef = useRef<any>(null);
  const mapCardRef = useRef<HTMLElement | null>(null);

  const mapResultsCount = mappedOrgs?.length || 0;
  const mapResultsLabel = `${t('home.map.chooseFrom')} ${mapResultsCount} ${t('home.map.offerings')}`;

  const selectedFiltersCount = useAppSelector(selectAppliedFiltersCount);

  const [isOffersSidebarVisible, setIsOffersSidebarVisible] = useState(true);
  const [treesPoints, setTreesPoints] = useState<any>(organisations);
  const [clusterPoints, setClusterPoints] = useState<Supercluster>([]);
  const [infoCardData, setInfoCardData] = useState<MarkerType | null>(null);
  const [hoveredElement, setHoveredElement] = useState<MarkerType | null>(null);
  const [isOrganizationPage, setIsOrganizationPage] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const defaultZoom = mapZoom || 12;

  const [mapProps, setMapProps] = useState<MapPropsType>({
    center: mapCenter,
    zoom: defaultZoom,
    bounds:
      area && area.NE && area.SW
        ? [area.SW.lng, area.SW.lat, area.NE.lng, area.NE.lat]
        : [telAvivBounds.SW.lng, telAvivBounds.SW.lat, telAvivBounds.NE.lng, telAvivBounds.NE.lat],
  });

  const [googleMapGeometry, setGoogleMapGeometry] = useState<GoogleGeometryType | null>(null);

  useClickOutside(mapCardRef, () => {
    setInfoCardData(null);
  });

  const handleClusterClick = (marker: MarkerType, supercluster: Supercluster) => {
    if (mapRef.current) {
      const childZoom = supercluster.getClusterExpansionZoom(marker.id);
      mapRef.current.setZoom(childZoom);
      mapRef.current.panTo({
        lng: marker.x ? xLng(marker.x) : mapCenter.lng,
        lat: marker.y ? yLat(marker.y) : mapCenter.lat,
      });
    }
  };

  const handleElementHover = (marker: MarkerType) => {
    if (!hoveredElement) {
      setHoveredElement(marker);
    }

    if (hoveredElement && hoveredElement.id !== marker.id) {
      setHoveredElement(marker);
    }
  };

  const getMapCard = (
    geometry: GoogleGeometryType | null,
    userMarker: LatLngLiteral,
    marker: MarkerType,
    ref: MutableRefObject<HTMLElement | null>,
  ) => {
    const distanceInKm = calculateDistance(geometry, userMarker, getMarkerLatLngLiteral(marker));
    const isDown = ref.current && ref.current?.getClientRects()[0].y < Y_OFFSET_FOR_DOWN_LAYOUT;

    return (
      <CardWrapper
        ref={ref}
        onClick={() => {
          dispatch(updateShowMenu({ isShowMenu: true }));
          openLink(marker.id, `?org=${marker.title}&service=${topCategory}`);
        }}
        isDown={isDown}
      >
        <MapCard
          title={marker.name}
          distance={`${distanceInKm} Km`}
          distanceText={t('home.map.awayFromYou')}
          // rating={marker.rating}
          reviewCount=""
          reviewsText={t('organisation.reviews', { count: Number(marker.review) })}
          image={marker.image}
        />
      </CardWrapper>
    );
  };

  useEffect(() => {
    const isOrganization = location.pathname.includes('/organisation');
    setIsOrganizationPage(isOrganization);
    setIsOffersSidebarVisible(!isOrganization);
  }, [location]);

  const supercluster = useMemo(() => new Supercluster(clusterOptions), []);
  supercluster.load(mappedOrgs);

  useEffect(() => {
    setTreesPoints(supercluster.trees[mapProps?.zoom || defaultZoom].points);
    setClusterPoints(supercluster.points);
  }, [mapProps, supercluster, organisations]);

  // Actions which would run after map and google api were loaded
  const onGoogleApiLoaded = useCallback(
    (map: GoogleMapType, maps: any) => {
      mapRef.current = map;
      mapsControlsRef.current = maps;

      setGoogleMapGeometry(google.maps.geometry.spherical); // Save Geometry API reference to use further

      const rightBottomButtons = getRightBottomButtons(
        map,
        mapCenter,
        defaultZoom,
        onFilterOpen,
        selectedFiltersCount,
        isLarge,
        theme,
      );

      const rightTopButtons = getRightTopMapButtons({
        count: selectedFiltersCount,
        onClick: onFilterOpen,
        onClose: onChangeView,
        isVisibleFilter: !isOrganizationPage && isLarge,
        theme,
      });

      if (isLarge) {
        const leftTopButtons = getLeftTopMapButtons({
          isVisible: isOffersSidebarVisible,
          onClick: value => setIsOffersSidebarVisible(value),
          label: mapResultsLabel,
          isVisibleLeftBlock: !isOrganizationPage,
          theme,
        }) as HTMLDivElement;
        map.controls[theme.rtl ? maps.ControlPosition.RIGHT_TOP : maps.ControlPosition.LEFT_TOP].push(leftTopButtons);
      }

      map.controls[theme.rtl ? maps.ControlPosition.LEFT_BOTTOM : maps.ControlPosition.RIGHT_BOTTOM].push(
        rightBottomButtons,
      );
      map.controls[theme.rtl ? maps.ControlPosition.LEFT_TOP : maps.ControlPosition.RIGHT_TOP].push(rightTopButtons);
    },
    [isOrganizationPage, isLarge, theme.rtl],
  );

  // Change visible map bounds(borders) when user navigates on map
  const onChange = ({ center, zoom, bounds }) => {
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    setMapProps({ center, zoom, bounds: [sw.lng(), sw.lat(), ne.lng(), ne.lat()] });
  };

  // TODO Review and optimize
  useEffect(() => {
    const rightBottomButtons = getRightBottomButtons(
      mapRef?.current as GoogleMapType,
      mapCenter,
      defaultZoom,
      theme,
      onFilterOpen,
      selectedFiltersCount,
      isLarge,
    );

    const rightTopButtons = getRightTopMapButtons({
      count: selectedFiltersCount,
      onClick: onFilterOpen,
      onClose: onChangeView,
      isVisibleFilter: !isOrganizationPage && isLarge,
      theme,
    });

    clearMapButtons(mapRef, mapsControlsRef, isShowSearch, theme.rtl);

    mapRef?.current?.controls[
      theme.rtl
        ? mapsControlsRef.current?.ControlPosition.LEFT_BOTTOM
        : mapsControlsRef.current?.ControlPosition.RIGHT_BOTTOM
    ].push(rightBottomButtons);
    mapRef.current?.controls[
      theme.rtl ? mapsControlsRef.current?.ControlPosition.LEFT_TOP : mapsControlsRef.current?.ControlPosition.RIGHT_TOP
    ].push(rightTopButtons);

    if (!isOrganizationPage && isLarge) {
      const leftTopButtons = getLeftTopMapButtons({
        isVisible: isOffersSidebarVisible,
        onClick: value => setIsOffersSidebarVisible(value),
        label: mapResultsLabel,
        isVisibleLeftBlock: true,
        theme,
      }) as HTMLDivElement;
      mapRef.current?.controls[
        theme.rtl
          ? mapsControlsRef.current?.ControlPosition.RIGHT_TOP
          : mapsControlsRef.current?.ControlPosition.LEFT_TOP
      ].push(leftTopButtons);
    }
  }, [selectedFiltersCount, isOffersSidebarVisible, isOrganizationPage, isLarge, mapCenter, mapRef.current]);

  useEffect(() => {
    const map = mapRef.current;
    const newBounds = !(area && area.SW && area.NE)
      ? new google.maps.LatLngBounds(
          new google.maps.LatLng(telAvivBounds.SW.lat, telAvivBounds.SW.lng), // Юго-западная точка участка
          new google.maps.LatLng(telAvivBounds.NE.lat, telAvivBounds.NE.lng), // Северо-восточная точка участка
        )
      : new google.maps.LatLngBounds(
          new google.maps.LatLng(area.SW.lat, area.SW.lng), // Юго-западная точка участка
          new google.maps.LatLng(area.NE.lat, area.NE.lng), // Северо-восточная точка участка
        );
    geolocation && map?.setCenter(geolocation);
    newBounds && map?.fitBounds(newBounds);
    // setMapProps({ ...mapProps, center: geolocation, bounds: [area.SW.lng, area.SW.lat, area.NE.lng, area.NE.lat] });
  }, [area, geolocation]);

  return (
    <GoogleMapWrapper>
      <GoogleMap
        apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
        defaultCenter={mapCenter}
        defaultZoom={defaultZoom}
        options={options}
        mapMinHeight="80vh"
        onChange={onChange}
        onGoogleApiLoaded={({ map, maps }) => onGoogleApiLoaded(map, maps)}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_KEY,
          libraries: ['geometry'],
        }}
      >
        {treesPoints?.map((marker: MarkerType) =>
          marker.numPoints ? (
            <ClusterMarker
              key={`${marker.id}-cluster`}
              lng={marker.x && xLng(marker.x)}
              lat={marker.y && yLat(marker.y)}
              zIndex={6} // Place container over other markers when Info card is shown
              // renewed
              zoom={mapProps?.zoom || defaultZoom}
              markersNumber={marker.numPoints}
              onClick={() => handleClusterClick(marker, supercluster)}
            />
          ) : (
            <MarkerWrapper
              key={`${marker.index}-marker`}
              lng={marker.x && xLng(marker.x)}
              lat={marker.y && yLat(marker.y)}
              zIndex={infoCardData && marker.index === infoCardData.index ? 99 : 5} // Place container over other markers when Info card is shown
              onClick={() => {
                setHoveredElement(marker);
                setTimeout(() => {
                  setHoveredElement(null);
                });
                setInfoCardData(marker);
              }}
            >
              {isLarge &&
                infoCardData &&
                marker.index === infoCardData.index &&
                getMapCard(googleMapGeometry, userLocation, clusterPoints[marker.index], mapCardRef)}
              <PointWrapper>
                <RaiteTab
                  rate={clusterPoints[marker.index]?.rating || null}
                  isHighlighted={isOrganizationPage || clusterPoints[marker.index]?.id === hoveredElement?.id}
                />
              </PointWrapper>
            </MarkerWrapper>
          ),
        )}

        <MapPointLocation lat={userLocation.lat} lng={userLocation.lng} />
      </GoogleMap>
      {!isLarge && infoCardData && (
        <Flex justifyContent="center">
          {getMapCard(googleMapGeometry, userLocation, clusterPoints[infoCardData.index], mapCardRef)}
        </Flex>
      )}
      {isOffersSidebarVisible && (
        <FilterResultsSidebar
          sidebarLabel={mapResultsLabel}
          hoveredItemId={hoveredElement?.id}
          onHover={marker => {
            setInfoCardData(null);
            handleElementHover(marker);
          }}
          onLeave={() => setHoveredElement(null)}
          setShareModalOpen={setShareModalOpen}
          setFavouriteModalOpen={setFavouriteModalOpen}
          setShareOrganisationId={setShareOrganisationId}
        />
      )}
    </GoogleMapWrapper>
  );
};

export default CustomMap;
