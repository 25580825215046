import { MutableRefObject } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import {
  Flex,
  Div,
  Button,
  SlidersIcon,
  CloseIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
  MinusIcon,
  NavigationPointerIcon,
  BodySmall,
  BMTheme,
  Theme,
} from '@beauty/beauty-market-ui';
import { telAvivGeolocation } from '../../../../constants';
import { GoogleMapType, LatLngLiteral, MapButtonsType, MarkerType, GoogleGeometryType } from '../../../../types';
import { ArrowWrapper, LabelButton, NavigationButton, StyledButton, ZoomButtonsWrapper } from './style';

const getStyledComponentWrapper = (children: JSX.Element, theme: Theme) => (
  <BMTheme theme={theme}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </BMTheme>
);

const createMapElement = (children: JSX.Element, theme: Theme) => {
  const divWrapper = document.createElement('div');
  const root = createRoot(divWrapper);
  root.render(getStyledComponentWrapper(children, theme));
  return divWrapper;
};

const getZoomControlButtons = (map: GoogleMapType, defaultZoom: number) => {
  const handleZoomIn = () => {
    map.setZoom((map.getZoom() || defaultZoom) + 1);
  };

  const handleZoomOut = () => {
    map.setZoom((map.getZoom() || defaultZoom) - 1);
  };

  return (
    <ZoomButtonsWrapper>
      <Button
        size="small"
        design="withShadow"
        p="10px !important"
        borderBottomRightRadius="0"
        borderBottomLeftRadius="0"
        onClick={handleZoomIn}
      >
        <PlusIcon />
      </Button>
      <Button
        size="small"
        design="withShadow"
        p="10px !important"
        borderTopRightRadius="0"
        borderTopLeftRadius="0"
        onClick={handleZoomOut}
      >
        <MinusIcon />
      </Button>
    </ZoomButtonsWrapper>
  );
};

export const getRightBottomButtons = (
  map: GoogleMapType,
  mapCenter: LatLngLiteral,
  defaultZoom: number,
  theme: Theme,
  onClick,
  count?: number,
  isLarge = false,
) =>
  createMapElement(
    <>
      {!isLarge ? (
        <Div mr="16px">
          {/* <Button size="small" design="withShadow" p="10px !important" count={count || false} onClick={onClick}>
            <SlidersIcon />
          </Button> */}
        </Div>
      ) : (
        getZoomControlButtons(map, defaultZoom)
      )}
      <NavigationButton>
        <Button size="small" design="withShadow" p="10px !important" onClick={() => map?.panTo(mapCenter)}>
          <NavigationPointerIcon />
        </Button>
      </NavigationButton>
    </>,
    theme,
  );

export const getRightTopMapButtons = ({ count, onClick, onClose, isVisibleFilter = false, theme }: MapButtonsType) =>
  createMapElement(
    <StyledButton mt="40px">
      {/* {isVisibleFilter ? (
        <Div mr="16px">
          <Button
            size="small"
            design="withShadow"
            p="10px !important"
            count={count || false}
            onClick={() => onClick && onClick(true)}
          >
            <SlidersIcon />
          </Button>
        </Div>
      ) : null} */}
      <Button size="small" design="withShadow" onClick={() => onClose && onClose()} p="10px !important;">
        <CloseIcon />
      </Button>
    </StyledButton>,
    theme,
  );

export const getLeftTopMapButtons = ({
  isVisible,
  onClick,
  label,
  isVisibleLeftBlock = false,
  theme,
}: MapButtonsType) => {
  if (!isVisibleLeftBlock) {
    return null;
  }

  return createMapElement(
    isVisible ? (
      <ArrowWrapper>
        <Button p="10px !important" size="small" design="withShadow" onClick={() => onClick && onClick(false)}>
          <ArrowLeftIcon />
        </Button>
      </ArrowWrapper>
    ) : (
      <Div ml="24px" mt="40px">
        <LabelButton
          size="small"
          design="withShadow"
          suffix={<ArrowRightIcon width="20" height="20" />}
          onClick={() => onClick && onClick(true)}
        >
          <BodySmall>{label}</BodySmall>
        </LabelButton>
      </Div>
    ),
    theme,
  );
};

// see https://github.com/mapbox/supercluster/blob/8d2461505b8c3f245b878448be530d0df052959d/index.js#L389
export const xLng = (x: number) => (x - 0.5) * 360;

export const yLat = (y: number) => {
  const y2 = ((180 - y * 360) * Math.PI) / 180;
  return (360 * Math.atan(Math.exp(y2))) / Math.PI - 90;
};

export const calculateDistance = (geometry: GoogleGeometryType | null, from: LatLngLiteral, to: LatLngLiteral) => {
  const distanceInMeters = geometry ? geometry.computeDistanceBetween(from, to) : 0;
  const distanceInKm = (distanceInMeters / 1000).toFixed(2);
  return distanceInKm;
};

export const getMarkerLatLngLiteral = (marker: MarkerType) => {
  const lat = marker.geometry?.coordinates[1] || telAvivGeolocation.lat;
  const lng = marker.geometry?.coordinates[0] || telAvivGeolocation.lng;
  return { lat, lng };
};

export const clearMapButtons = (
  mapRef: MutableRefObject<google.maps.Map | null>,
  mapsControlsRef: MutableRefObject<any>,
  isShowSearch: boolean,
  rtl: boolean,
) => {
  mapRef.current?.controls[
    rtl ? mapsControlsRef.current?.ControlPosition.LEFT_TOP : mapsControlsRef.current?.ControlPosition.RIGHT_TOP
  ].clear();
  mapRef.current?.controls[
    rtl ? mapsControlsRef.current?.ControlPosition.LEFT_BOTTOM : mapsControlsRef.current?.ControlPosition.RIGHT_BOTTOM
  ].clear();
  isShowSearch &&
    mapRef.current?.controls[
      rtl ? mapsControlsRef.current?.ControlPosition.RIGHT_TOP : mapsControlsRef.current?.ControlPosition.LEFT_TOP
    ].clear();
};
